<template>
  <div>
    <header class="main-header">
      <div class="row row-page-top">
        <h1 class="poster-rail--title section-title">
          Últimas 24hs: {{ selectedChannel.name }}
        </h1>

        <div class="filters-toolbar">
          <Select
            cssClass="dropdown-channel"
            :list="channelsNameFilter"
            :onSelect="onSelectChannel"
            :showFullLoader="showFullLoader"
            keyField="idChannel"
            textField="name"
            title="Canal"
            searchTitle="Filtrar por canal:"
          />
        </div>
      </div>
    </header>

    <main class="wrapper container-fluid catch-up" role="main">
      <section class="row featured-content channel-rail">
        <template v-if="channels.length > 0">
          <Slider
            :data="channels"
            :showactive="true"
            :channelselector="true"
            :channelid="selectedChannel.id"
          >
            <template slot-scope="itemslider">
              <div
                v-for="(item, index) of itemslider.data"
                :key="item.idChannel"
                class="slider-item channel-item"
                :class="{ active: selectedChannel.id === item.idChannel }"
              >
                <router-link
                  :to="'/24-horas/' + item.idChannel + '/' + item.name"
                  href="#channelFilter"
                  title=""
                  class=""
                >
                  <figure>
                    <img
                      :src="item.logoCanal"
                      :alt="item.name"
                      class="channel-logo"
                    />
                    <figcaption>
                      {{ item.name }}
                    </figcaption>
                  </figure>
                </router-link>
              </div>
            </template>
          </Slider>
        </template>
      </section>

      <div class="wrapper-rails">
        <template v-if="selectedChannel.hoy.length > 0">
          <Slider :data="selectedChannel.hoy" :title="'Hoy más temprano'">
            <template slot-scope="itemslider">
              <CardTV
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                :showChannelButton="false"
                componentOrigin="CUT"
                :sliderId="'ult24_hoy'"
                :sliderTitle="'Hoy más temprano'"
                :cardId="index"
              />
            </template>
          </Slider>
        </template>

        <template v-if="selectedChannel.ayer.length > 0">
          <Slider :data="selectedChannel.ayer" :title="'Ayer'">
            <template slot-scope="itemslider">
              <CardTV
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                :showChannelButton="false"
                componentOrigin="CUT"
                :sliderId="'ult24_ayer'"
                :sliderTitle="'Ayer'"
                :cardId="index"
              />
            </template>
          </Slider>
        </template>

        <Loader v-if="showFullLoader" :display="'absolute'" />
      </div>
    </main>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Hours24",

  components: {
    CardTV: () => import("@/components/Card-TV.vue"),
    Loader: () => import("@/components/Loader.vue"),
    Slider: () => import("@/components/Slider.vue"),
    Select: () => import("@/components/Select.vue"),
  },

  data() {
    return {
      channels: [],
      filterChannelsText: "",
      selectedChannel: {
        id: "",
        name: "",
        hoy: [],
        ayer: [],
      },
      showFullLoader: true,
    };
  },

  created() {
    let self = this;

    self.changeCursor(true);

    telecentro.tplay.core.listChannel.obtenerCanales(function (ret) {
      self.channels = ret;

      self.showData(
        self.$route.params.channelId,
        self.$route.params.channelName
      );

      telecentro.tplay.core.epg.eventoCambioProgramacion.on(
        self.onCambioProgramacion
      );
    });
  },

  computed: {
    channelsNameFilter: function () {
      return this.findBy(
        this.channels,
        this.filterChannelsText.toUpperCase(),
        "name"
      );
    },
  },

  methods: {
    changeCursor(show) {
      if (show) {
        document.documentElement.style.cursor = "wait";
      } else {
        document.documentElement.style.cursor = "default";
      }
    },

    findBy(list, value, column) {
      return list.filter(function (item) {
        let name = item[column].toUpperCase();
        return name.includes(value);
      });
    },

    showData(channelId, channelName) {
      let self = this;

      //Sanitizo el input:
      channelId = parseInt(channelId);

      if (!channelId || !channelName) {
        //Actualizo la URL con el primer ítem, para que quede consistente.
        //Retorno para evitar procesar dos veces showData, ya que se va a llamar desde el watch del $router.
        self.$router.push(
          "/24-horas/" +
            self.channels[0].idChannel +
            "/" +
            self.channels[0].name
        );
        return;
      }

      self.showFullLoader = true;
      self.changeCursor(true);

      // Traigo la programación de hoy y de mañana
      telecentro.tplay.core.epg.obtenerProgramacionHoy(
        channelId,
        true,
        function (hoy) {
          telecentro.tplay.core.epg.obtenerProgramacionAyer(
            channelId,
            true,
            function (ayer) {
              const startTime =
                new Date().getTime() -
                telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000;

              self.selectedChannel.id = channelId;
              self.selectedChannel.name = channelName;
              self.selectedChannel.hoy = _.orderBy(
                hoy,
                (p) => p.startHourUnixTime,
                "startHourUnixTime"
              );
              self.selectedChannel.ayer = _.orderBy(
                ayer,
                (p) => p.startHourUnixTime,
                "startHourUnixTime"
              ).filter((p) => p.endHourUnixTime * 1000 >= startTime);

              self.$nextTick(function () {
                setTimeout(function () {
                  self.showFullLoader = false;

                  self.changeCursor(false);
                }, 1000);
              });
            }
          );
        }
      );
    },

    onCambioProgramacion(lcn, programacionTV) {
      if (programacionTV.channel.id === this.selectedChannel.id) {
        if (programacionTV.idAsset !== this.selectedChannel.hoy[0].idAsset) {
          this.showData(programacionTV.channel.id, programacionTV.channel.name);
        }
      }
    },

    onSelectChannel(channel) {
      this.$router.push("/24-horas/" + channel.idChannel + "/" + channel.name);
    },
  },

  watch: {
    $route(to, from) {
      this.showData(
        this.$route.params.channelId,
        this.$route.params.channelName
      );
    },
  },

  beforeDestroy() {
    telecentro.tplay.core.epg.eventoCambioProgramacion.off(
      this.onCambioProgramacion
    );
  },
};
</script>
